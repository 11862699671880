import MapConfig from '../config/map_config';
import Config from "../config/constants";
import {
  connectDataAjaxCall,
  fitMapToBounds,
  geofenceAjaxCall,
  hazardTagAjaxCall,
  imageDataAjaxCall,
  locationDataAjaxCall,
  machineTagLocationCallWithDateRange,
  machineTagLocationDataAjaxCall, machineZoneAjaxCall,
  perimeterAjaxCall,
  plotLocationMarkers,
  showAllMarkersOnMapInRange,
  tunnelAjaxCall,
  tunnelTagChainAjaxCall
} from './map_ajax';
import {getInitialSite, siteCoordinates, siteId, updateSiteCoordinates} from './site_management';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';

document.addEventListener("turbolinks:load", () => {
  if ($('#map').length) {
    // if (document.getElementById('is_live').value == "" || document.getElementById('is_live').value === "false"){
    //   // Scroll to the center of the timeline
    //   document.querySelector('.active').scrollIntoView({
    //     behavior: 'auto',
    //     block: 'center',
    //     inline: 'center'
    //   });
    // }
    $.when(getInitialSite()).done(() => {
      let latitude = document.getElementById('latitude').value;
      let longitude = document.getElementById('longitude').value;
      let coordinates;
      if (latitude && longitude) {
        coordinates = `${longitude} ${latitude}`
      }
      initiateMap(coordinates);
      let dots = document.getElementsByClassName('day-dot');
      for (let i = 0; i < dots.length; i++){
        dots[i].addEventListener('click', dotClicked);
      }
    })
    let map_site_id = document.getElementById('map_site_id');
    if (map_site_id) {
      map_site_id.onchange = function () {
        var siteId = map_site_id.value;
        updateSiteCoordinates(siteId).then(response => {initiateMap(response.coordinates, null, siteId);});
      }
    }
  }
})

function dotClicked(){
  let dateValues = this.querySelector('span').dataset.info.split(":")[0];
  let start_of_day = moment(dateValues).startOf('day').format('YYYY-MM-DD');
  let end_of_day = moment(dateValues).endOf('day').format('YYYY-MM-DD');
  // Need to include the times, else it will be converted to one day behind
  initiateMap(null, start_of_day + " 00:00 to " + end_of_day + " 23:59");
  let dots = document.getElementsByClassName('day-dot');
  for (let i = 0; i < dots.length; i++){
    dots[i].classList.remove('active');
  }
  this.classList.add('active');
}

let userIds = [];
let machineIds = [];
let eventTypeIds = [];
let geofenceIds = [];
let precisionLevel = null;

// need the map object to set the AJAX calls
// on the window method of setInterval
let mapObject;

const initiateMap = (coordinates, date_range, site_id) => {
  $('.progress-bar').show();

  let site_coords, siteIdToUse;
  if (coordinates){
    site_coords = coordinates.split(" ");
  }else {
    site_coords = siteCoordinates;
  }
  if (site_id){
    siteIdToUse = site_id;
  }else{
    siteIdToUse = siteId;
  }
  const tunnel = $("#is_tunnel").val();
  let style = MapConfig.regularStyle;
  if (tunnel) {
    style = MapConfig.darkStyle;
  }
  mapboxgl.accessToken = Config.mapboxAccessToken;
  const map = new mapboxgl.Map({
    container: 'map',
    style: style,
    center: site_coords,
    zoom: MapConfig.startingZoom,
    minZoom: MapConfig.zoomOutMax,
    maxZoom: MapConfig.zoomInMax
  });

  fitMapToBounds(map, siteIdToUse, site_coords);

  map.addControl(new mapboxgl.FullscreenControl());
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
    })
  );
  //**************************************************
  //**************************************************


  //**************************************************
  //**************************************************
  // MAP EVENT LISTENERS
  map.on('mousemove', (e) => {
    document.getElementById('latlong-text').innerText =
        // `e.lngLat` is the longitude, latitude geographical position of the event.
        Number(e.lngLat.lat).toFixed(4) + ', ' + Number(e.lngLat.lng).toFixed(4)
  });

  addPolygonsMouseOver(map, 'polygons');
  addPolygonsMouseOver(map, 'machine_zones');

  map.on('load', () => {
    // Add buildings to the map from from mapbox streets vector tileset. The data from this is from OpenStreetMap
    // Insert the layer beneath any symbol layer.
    const layers = map.getStyle().layers;
    const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;

    // The 'building' layer in the Mapbox Streets
    // vector tileset contains building height data
    // from OpenStreetMap.
    map.addLayer(
        {
          'id': 'add-3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': 15,
          'paint': {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.35
          }
        },
        labelLayerId
    );

    // Need to get the ids of all users/groups in the selectize element
    let userSelect = document.getElementById('user_select');
    let children = userSelect.children;
    userIds = [];
    for (let i = 0; i < children.length; i++) {
      userIds.push(children[i].value.split(","));
    }
    let range_date_param;
    if (date_range){
      range_date_param = date_range;
    }else{
      range_date_param = document.getElementById('date_range_param').value;
    }
    machineIds = document.getElementById('machines').value;
    eventTypeIds = document.getElementById('event_types').value;
    geofenceIds = document.getElementById('geofences').value;
    precisionLevel = document.getElementById('high_precision').value;
    var layerList = document.getElementById('style-menu');
    var inputs = layerList.getElementsByTagName('input');

    function switchLayer(layer) {
      var layerId = layer.target.id;
      if(layerId == "default"){
        if (tunnel) {
          map.setStyle(MapConfig.darkStyle);
        } else {
          map.setStyle('mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9');
        }
        // Populate data on the map
        populateMap(map, range_date_param, false);
      }else{
        map.setStyle('mapbox://styles/mapbox/' + layerId);
        // Populate data on the map
        populateMap(map, range_date_param, false);
      }
    }

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].onclick = switchLayer;
    }

    // Populate data on the map
    populateMap(map, range_date_param, true);
  });

  // Slider is enabled when the date / time range selected is an hour or less
  if (document.getElementById('slider')) {
    document.getElementById('slider').addEventListener('input', function(e) {
      const date_param = document.getElementById('date_param').value
      const default_slider_value = 30
      let new_slider_value = parseInt(e.target.value);
      // figure out how much to remove or add in terms of time.
      let date_param_to_date = new Date(date_param)
      let new_date;
      if (default_slider_value > new_slider_value) {
        //taking away time
        let value_to_remove = default_slider_value - new_slider_value
        new_date = new Date(date_param_to_date.setMinutes(date_param_to_date.getMinutes() - value_to_remove))
      } else {
        //add time
        let value_to_add = new_slider_value - default_slider_value
        new_date = new Date(date_param_to_date.setMinutes(date_param_to_date.getMinutes() + value_to_add))
      }

      // Update location and sos markers based on slider
      map.setFilter('locations', ['==', ['number', ['get', 'minute']], moment(new_date).minutes()]);
      map.setFilter('sos', ['==', ['number', ['get', 'minute']], moment(new_date).minutes()]);

      document.getElementById('active-time').innerText = "Minute: " + moment(new_date).format("mm")
    });
  }
  //**************************************************
  //**************************************************
  mapObject = map;
}

//**************************************************
// SET INTERVALS FOR MAKING AJAX REQUESTS
//**************************************************
// ADD LOCATION POINTS ON MAP
window.setInterval(() => {
  if ($('#map').length && document.getElementById('date_param').value == "" && document.getElementById('date_range_param').value == "") {
    const tunnel = $("#is_tunnel").val();
    let isTunnel = false
    if (tunnel) {
      isTunnel = true
    }
    // Only show live locations when they have been filtered for
    if ($('#is_live').attr('value') === "true") {
      locationDataAjaxCall(mapObject, siteId, userIds, isTunnel);
      machineTagLocationDataAjaxCall(mapObject, siteId, isTunnel, true)
    }
  }
}, MapConfig.locationFrequency);
//**************************************************
//**************************************************

async function populateMap(map, range_date_param, should_show_menu){
  let machineZoneIds = $("#machine_zone_ids").val();
  const tunnel = $("#is_tunnel").val();
  if (tunnel) {
    tunnelAjaxCall(map);
    const ttcId = $('#tunnel_tag_control_id').val();
    tunnelTagChainAjaxCall(map, ttcId, false, false);

    // Only show historical data if filtered for historical data
    if ($('#is_live').attr('value') !== "true") {
      showAllMarkersOnMapInRange(map, null, null, range_date_param, precisionLevel, eventTypeIds);
      await imageDataAjaxCall(map, null, range_date_param, precisionLevel, true, false, false, should_show_menu, null, siteId, null, false, true, true);
    } else {
      locationDataAjaxCall(map, siteId, userIds, true);
      machineTagLocationDataAjaxCall(map, siteId, true, true)
    }
  } else {
    connectDataAjaxCall(map);
    perimeterAjaxCall(map, null, siteId, true, false);
    hazardTagAjaxCall(map, siteId);

    plotImages(map, geofenceIds, range_date_param, precisionLevel, should_show_menu, siteId).then(function(value){
      plotGeofences(map, geofenceIds, range_date_param, precisionLevel, should_show_menu).then(function(value) {
        machineZoneAjaxCall(map, machineZoneIds, false, true, null, null, null);
        if (range_date_param && range_date_param !== "") {
          showAllMarkersOnMapInRange(map, userIds, geofenceIds, machineZoneIds, range_date_param, precisionLevel, eventTypeIds);
          machineTagLocationCallWithDateRange(map, range_date_param, precisionLevel);
        }else{
          locationDataAjaxCall(map, siteId, userIds, false);
          machineTagLocationDataAjaxCall(mapObject, siteId, false, true)
        }
      })
    })
  }
}

async function plotImages(map, geofenceIds, range_date_param, precisionLevel,should_show_menu, siteId) {
  $('.progress-bar').show();
  let imagePromise = new Promise(function(resolve) {
    resolve(imageDataAjaxCall(map, geofenceIds, range_date_param, precisionLevel, true, false, false, should_show_menu, null, siteId, null, false, true, false));
  })

  return await imagePromise;
}

async function plotGeofences(map, geofenceIds, range_date_param, precisionLevel,should_show_menu) {
  $('.progress-bar').show();
  let geofencePromise = new Promise(function(resolve) {
    resolve(geofenceAjaxCall(map, geofenceIds, range_date_param, precisionLevel, true, false, false, should_show_menu, null, false, null, false, false, null, null, null));
  })

  return await geofencePromise;
}

export function addPolygonsMouseOver(map, layer_id){
  var hoveredStateId = null;

  // Add mouseover event for the polygons layer to show the polygon name
  map.on('mousemove', layer_id, (e) => {
    if (e.features.length > 0) {
      hoveredStateId = e.features[0].properties.title;
      var cpos = { top: e.originalEvent.pageY + 10, left: e.originalEvent.pageX + 10 };
      $('#besideMouse').offset(cpos);
      $('#besideMouse').text(hoveredStateId);
      $('#besideMouse').show();
    }
  });

  // When the mouse leaves the polygons layer clear the hover text
  map.on('mouseleave', layer_id, () => {
    $('#besideMouse').text('');
    $('#besideMouse').hide();
    hoveredStateId = null;
  });
}

export { mapObject, userIds, eventTypeIds, geofenceIds, machineIds };
